import { graphql } from 'gatsby'
import React from "react";
import MenuContextProvider from "@/context/menu-context";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import Footer from "@/components/footer";
import PortableText from '@/components/portableText'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade, Lazy } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import Link from "@/components/link";
import Img from "@/components/img";
import Seo from '@/components/seo';
import CookieConsent from "react-cookie-consent";
import imageUrlBuilder from '@sanity/image-url'
import clientConfig from '../../client-config'


SwiperCore.use([Autoplay, Navigation, EffectFade, Lazy]);

const HomeOne = ({location, data, qty}) => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    effect: "fade",
    lazy: true,
    maxBackfaceHiddenSlides: 1,
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 10000
    }
  };
  const homePage = data.sanityHomePage;
  const heroBlocks = data.sanityHomePage.heroBlock;
  const iconBanner = data.sanityHomePage.iconBanner;
  const highlightBlock = data.sanityHomePage.highlightBlock;
  const posts = data.posts.nodes;
  const attorneys = data.attorneys.nodes;

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <MenuContextProvider>
      <Seo
        title="Ferrara Fiorenza PC"
        description="Ferrara Fiorenza PC offers a blend of expertise and thoughtful representation in analyzing and solving the challenges faced by school districts, BOCES and all types of employers."
        image=""
        location={location}
      />
      <Layout PageTitle="Home Page">
        <HeaderOne />
        {/* Slider */}
        <section className="main-slider header_slider_area ">
          <Swiper {...mainSlideOptions} lazy={true}>
            {homePage?.heroBlock && heroBlocks.map(
              ({ image, title, tagline, cta, link }, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="image-layer"
                    style={{ backgroundImage: `url(${image.asset.url})` }}
                  ></div>

                  <Container>
                    <Row>
                      <Col lg="6 bg-gray-600 rounded-lg p-4 bg-opacity-80	">
                        <p className="text-white font-normal	">
                          {tagline}
                        </p>
                        <h1 className="main-slider__title text-white">{title}</h1>
                        <div className="slide_button">
                          <Link href={link.slug.current} className="btn-yellow font-md rounded-lg">
                            {cta}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </section>
        {/* Features */}
        {homePage?.iconBanner &&
          <div className="feature_service_area text-center ">
            {iconBanner.map(({ icon, text, link, external, linkUrl }, index) => (
              <div className="feature_service_box_width shadow rounded-lg" key={`feature-one-${index}`}>
                {external === true &&
                <a href={linkUrl}>
                    <i className={'text-5xl text-white mb-6 fa fa-'+ icon.name}></i>
                    <p className='text-white'>{text}</p>
                </a>}
                {external !== true && link._type === "post" &&
                <a href={"articles/"+link.slug.current}>
                    <i className={'text-5xl text-white mb-6 fa fa-'+ icon.name}></i>
                    <p className='text-white'>{text}</p>
                </a>}
                {external !== true && link._type === "page" &&
                <a href={link.slug.current}>
                    <i className={'text-5xl text-white mb-6 fa fa-'+ icon.name}></i>
                    <p className='text-white'>{text}</p>
                </a>}
                {external !== true && link._type === "event" &&
                <a href={"articles/"+link.slug.current} >
                    <i className={'text-5xl text-white mb-6 fa fa-'+ icon.name}></i>
                    <p className='text-white'>{text}</p>
                </a>}
              </div>
            ))}
          </div>
        }

        {/* Services */}
        <section className="service_area section_padding">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="hero-section-title text-center">
                  <h1>What We Do</h1>
                </div>
              </div>
              {/* School Law */}
                <Col lg={6} className="flex flex-1">
                  <div className="service_box flex flex-col p-2">
                    <div className="service_img flex-none">
                      <Img src={homePage.schoolLawBlock.image.asset.url} alt="school law" className="img-fluid" />
                      <div className="icon-box flex items-center justify-center">
                        <i className={'text-5xl text-gray-800 fa fa-'+ homePage.schoolLawBlock.icon.name}></i>
                      </div>
                    </div>
                    <div className="service_details relative flex-1">
                      <Link href={homePage.schoolLawBlock.link.slug.current}>
                        <h2>{homePage.schoolLawBlock.title}</h2>
                      </Link>
                      <PortableText className="flex-1" blocks={homePage.schoolLawBlock._rawDescription} />
                    </div>
                    <a href={homePage.schoolLawBlock.link.slug.current} style={{borderRadius: "0px !important"}}  className="flex-none btn-yellow">
                        {homePage.schoolLawBlock.cta}
                      </a>
                  </div>
                </Col>
                {/* Employment Law */}
                <Col lg={6} className="flex flex-1">
                  <div className="service_box flex flex-col p-2">
                    <div className="service_img flex-none">
                      <Img src={homePage.employmentLawBlock.image.asset.url} alt="service 1" className="img-fluid" />
                      <div className="icon-box flex items-center justify-center">
                        <i className={'text-5xl text-gray-800 fa fa-'+ homePage.employmentLawBlock.icon.name}></i>
                      </div>
                    </div>
                    <div className="service_details flex-1 rounded-lg relative">
                      <Link href={homePage.employmentLawBlock.link.slug.current}>
                        <h2>{homePage.employmentLawBlock.title}</h2>
                      </Link>
                      <PortableText className="flex-1" blocks={homePage.employmentLawBlock._rawDescription} />
                    </div>
                    <a href={homePage.employmentLawBlock.link.slug.current} style={{borderRadius: '0px!important'}} className="btn-yellow flex-none" >
                        {homePage.employmentLawBlock.cta}
                      </a>
                  </div>
                </Col>
            </div>
          </div>
        </section>
        {/* Call to Action */}
        {/* <section className="call_to_action">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-sm-8 col-xs-12">
                <h1>{homePage.actionBanner.title}</h1>
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12 text-end cta_responsive_left">
                <Link href={homePage.actionBanner.link.slug.current} className="btn-gray text-md">
                  {homePage.actionBanner.cta}
                </Link>
              </div>
            </div>
          </div>
        </section> */}
         {/* About */}
         {homePage?.highlightBlock.length > 0 &&
          <section className="about_area section_padding">
            {highlightBlock.map(({ title, tagline, image, link, _rawContent, cta }, index) => (
            <div className="container mb-12" key={index}>
              <div className="row">
                <div className="col-md-6">
                  <div style={image?.asset && { backgroundImage: `url(${image.asset.url})`, backgroundPosition: 'center' }}  className="about_image rounded about-image__updated"></div>
                </div>
                <div className="col-md-6">
                  <div className="about_details">
                    <div className="hero-title-with-shape">
                      <h4 className="heading_with_border">{tagline}</h4>
                      <h1>{title}</h1>
                    </div>
                    <PortableText blocks={_rawContent} />
                    {cta !== null &&
                      <div>
                        {link !== null && link._type === "post" &&
                        <a href={"articles/"+link.slug.current} className="btn-yellow">
                          {cta}
                        </a>}
                        {link !== null && link._type === "page" &&
                        <a href={link.slug.current} className="btn-yellow">
                          {cta}
                        </a>}
                        {link !== null && link._type === "event" &&
                        <a href={"articles/"+link.slug.current} className="btn-yellow">
                          {cta}
                        </a>}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
             ))}
          </section>
        }
        {/* Blog Posts */}
        <section className="blog_share_area section_padding">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="hero-section-title text-center">
                  <h1>Latest Posts</h1>
                </div>
              </Col>
              {posts.map(posts => (
                <Col lg={4} md={6} sm={12} key={posts._id}>
                  <div className="blog_share_box">
                    {posts.mainImage != null ?
                      <div className="bl_share_img">
                          <Img src={posts.mainImage.asset.url} alt={posts.title} className="img-fluid" />
                        {posts.publishedAt != null &&<span className="blog_date">{posts.publishedAt}</span>}
                      </div>
                    :
                    <div className="bl_share_img">
                        <Img src="../../breadcrumb.jpg" alt={posts.title} className="img-fluid" />
                        {posts.publishedAt != null &&<span className="blog_date">{posts.publishedAt}</span>}
                    </div> 
                    }
                    <div className="blog_share_details">
                    {posts.author != null &&
                      <span className="comment_author">
                        by <Link href={"/articles/"+posts.slug.current}>{posts.author.name}</Link>
                      </span>
                      }
                      <h1>
                        <Link href={"/articles/"+posts.slug.current}>{posts.title}</Link>
                      </h1>
                      {posts.author != null && <p dangerouslySetInnerHTML={{__html: posts.excerpt}}></p>}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        {/* Attorneys */}
        <section className="team_member_area section_padding text-center section_border bg-gray-300">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="hero-section-title text-center">
                  <h1>Meet Our Attorneys</h1>
                </div>
              </Col>
              {attorneys.splice(Math.floor(Math.random()*attorneys.length), 3).map(attorneys => (
                attorneys.hideFromFront !== true &&
                <Col lg md={12} sm={12} key={attorneys.id}>
                <div className="team_member bg-white rounded-lg mb-3">
                    <a href={"/attorneys/"+ attorneys.slug.current}>
                      <img src={urlFor(attorneys.image).width(500).height(300).url()} alt="Ferrara Attorney" className="img-fluid rounded-lg"/>
                    </a>
                    <div className="team_details">
                    <a href={"/attorneys/"+ attorneys.slug.current}>
                      <h3 className="mb-0">
                        {attorneys.name}<br/> 
                      </h3>
                      <span className="font-light text-sm text-gray-500 mb-4">{attorneys.title}</span>
                      </a>
                    </div>
                  </div>
                </Col>
              ))}
              <Col lg={12}>
                <div className="hero-section-title text-center mt-4">
                  <p><Link href="/attorneys" className="btn btn-yellow">Visit Our Team Page</Link></p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Contact */}
        <section className="contact_form_area">
          <div className="contact_form_width">
            <div id="map">
              <iframe
                title="template google map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11654.541282051277!2d-76.0770375!3d43.0911617!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9a9bfafb203aade2!2sFerrara%20Fiorenza%20PC!5e0!3m2!1sen!2sus!4v1647870944706!5m2!1sen!2sus"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="contact_form_width contact-right">
            <div className="hero-title-with-shape">
              <h1 className="mb-6">Connect with us</h1>
            </div>
            <div>
              <div className="contact-right contact-right-style-2 responsive_mt">
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="Contact">
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="Contact" />
                  <input
                    className="half_width input_m_right"
                    type="text"
                    name="name"
                    placeholder="Your name"
                  />
                  <input
                    className="half_width"
                    type="EMAIL"
                    name="email"
                    placeholder="Email address"
                  />
                  <input type="tel" name="tel" placeholder="Phone number" />
                  <textarea
                    name="content"
                    id="content"
                    cols="20"
                    rows="5"
                    placeholder="Write message"
                    className='w-full p-4'
                  ></textarea>
                  <button className="btn-yellow mt-3" value="SUBMIT">
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <CookieConsent
        buttonStyle={{ background: "#BFD7B5", fontSize: "13px" }}
        style={{ background: "#1f1f1f" }}

        expires={120}
        >This website uses cookies to enhance the user experience.</CookieConsent>

      </Layout>
    </MenuContextProvider>
  );
};

export default HomeOne;

export const query = graphql`
  query homePage {
    sanityHomePage {
        actionBanner {
          title
          link{
            ... on SanityAttorney {
              id
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              slug {
                current
              }
            }
            ... on SanityPost {
              id
              slug {
                current
              }
            }
          }
          cta
        }
        employmentLawBlock {
          cta
          icon {
            name
            provider
          }
          title
          link{
            ... on SanityAttorney {
              id
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              slug {
                current
              }
            }
            ... on SanityPost {
              id
              slug {
                current
              }
            }
          }
          image {
            asset {
              url
            }
          }
          _rawDescription
        }
        schoolLawBlock {
          cta
          icon {
            name
            provider
          }
          title
          link{
            ... on SanityAttorney {
              id
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              slug {
                current
              }
            }
            ... on SanityPost {
              id
              slug {
                current
              }
            }
          }
          image {
            asset {
              url
            }
          }
          _rawDescription
        }
        highlightBlock {
          cta
          link{
            ... on SanityAttorney {
              id
              _type
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              _type
              slug {
                current
              }
            }
            ... on SanityPost {
              id
              _type
              slug {
                current
              }
            }
          }
          tagline
          title
          _rawContent
          image {
            asset {
              url
            }
          }
        }
        iconBanner {
          icon {
            name
            provider
          }
          linkUrl
          external
          text
          link {
            ... on SanityEvent {
              id
              _type
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              _type
              slug {
                current
              }
            }
            ... on SanityPost {
                id
              _type
              slug {
                current
              }
            }
          }
        }
        heroBlock {
          image {
            asset {
              url
            }
          }
          cta
          tagline
          title
          link {
            ... on SanityAttorney {
              id
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              slug {
                current
              }
            }
            ... on SanityPost {
              id
              slug {
                current
              }
            }
          }
        }
      }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      limit: 3
    ) {
      nodes {
        publishedAt(formatString: "MMM Do, YYYY")
        slug {
          current
        }
        title
        mainImage {
          asset {
            url
          }
        }
        author {
          name
        }
        _id
        excerpt
      }
    }
    attorneys: allSanityAttorney {
      nodes {
        name
        title
        phone
        linkedIn
        hideFromFront
        email
        id
        slug {
          current
        }
        image {
          asset {
            url
          }
        }
      }
    } 
}
  
`